<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="uil uil-file-graph display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Factoring application list and information</small>
              </div>

              <div class="ms-auto mb-lg-0 mb-2">
               <router-link
                v-if="create_modify_permission"
                class="btn btn-outline-info fw-medium"
                :to="{
                    name: 'New Factoring Application'
                }"
                >
                <span class="d-none d-lg-block"><i class="uil uil-plus font-size-16 me-1"></i>  Create a New Application</span>
                <span class="d-block d-lg-none"><i class="uil uil-plus font-size-16 me-1"></i> New Application</span>
                </router-link
              >
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label mb-1">Applicant</label>
                <input
                    type="text"
                    placeholder="Company Name, Contact Name"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchKeyword"
                  />
              </div>
              
              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Status</label>
                    <select class="form-select pe-5" v-model="status" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Status</option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </select>
              </div>
              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Originator</label>
                  <multiselect
                  ref="select"
                  autocomplete="off"
                  v-model="selectedUser"
                  :options="userList"
                  label="name"
                  placeholder="Select Originator"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  class="font-size-15"
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="handlePageChange(1)"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.account_db_code==''">
                    <span class="option__title"> {{ props.option.name }} 
                    </span>
                  </span>
                  <span v-else>{{ props.option.name }}  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.account_db_code==''">
                    <span class="option__title"> {{ props.option.name }}  </span>
                  </span>
                  <span v-else>{{ props.option.name }}</span>
                </template>
                <span slot="noResult">Oops! No User found. </span>
                </multiselect>
              </div>

              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-md-4 col-lg-4 mb-3">
                <label class="form-label mb-1">Payment Mode</label>
                    <select class="form-select pe-5" v-model="paymentMode" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Payment Mode</option>
                      <option value="eP">ePerolehan</option>
                      <option value="Non-eP">Non-ePerolehan</option>
                    </select>
              </div>
              <div class="col-12 col-md-4 col-lg-4 mb-3">
                <label class="form-label mb-1">Nature of Contract</label>
                    <select class="form-select pe-5" v-model="sortByNatureOfContract" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All</option>
                      <option v-for="(value, index) in  nature_of_contract" :value="value.value" :key="index">{{value.label}}</option>
                    </select>
              </div>
              <div class="col-12 col-md-4 col-lg-4 mb-3">
                <label class="form-label mb-1">Sort By</label>
                    <select class="form-select pe-5" v-model="sortBy" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">Default</option>
                      <option value="name_a_z">Applicant Name (A-Z)</option>
                      <option value="name_z_a">Applicant Name (Z-A)</option>
                    </select>
              </div>
              <div class="col-12 col-md-auto col-lg-auto">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-md col-lg-4 mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-md-auto col-lg-auto">
                <label class="form-label mb-1 d-none d-md-block">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl d-none">
                <div class="float-end">
                  <div class="mb-3 ml-auto">
                    <label class="form-label mb-1">Export</label>
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>

              
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-1 ml-auto">
                <div class="d-flex align-items-center justify-content-lg-end">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEUElEQVR4nO2Za4hVVRTHfzNjmqY1RWUphdWIFRkUhFpTakr2UEgUVArpob3QvgiZpPZCSAS/mUYqGWqhfYjEB/lCHSUhEHrrlKaTEOUjx9LKx40V/w2Lzb0z3rvvHc/A/GHDXWuvs9be56691+NABzpQDHoDXwCngLXAWKAz7RDvA7lo/A7MB66ineAyoBk4DwwEpgJ73IY2kVFcC3wM/AHsBdZowTsjuaHi/0lGYW84l2f8AEwCLpfc8+J/TgZxj/P/OmBcng39BSwDvhL9BBnEAi1uvuiZoj8BJgJbdVbCpk4A3cgYLgWOaoF3AlXAPtEPObmbgQbx7TbLHIIbfSm6XvQvQI2TqwYOae4+Moh1WtyUKHbMieRGiL9P/1qm0As4C/wDXA10BY5rwbdGsivFf40M4lUtbrXoJ0XviuSu0K11DriRDOI7Lfwx0RtFW6zweE58m88cBmpxvwKdlCCeBU4DtZHsTsnaP5Y5LNLi5oo23zf6o0iur2LISaA7GYwd4VD3F+970Q9HsnPEX1wOw9WKsNv0Zo7q2hxUor4JWtxu0fe6qL0GGKUY4mPH/ambqG0hofsXGFNClrtbz78o3nt5dB8Gluv3j6mxw+717VJ2BJisu982N9vlPddcoL5dbqFN8vmuSt2N9wAwzblZGLNIxAop+hm4Ic/8Bs1Pv0B9DXJNSwZvidzMSlqPR8W32NEnZRMjpciqtH4FZEIA25xgJ7yMFyL+JPG3JOj+v8A/IEUvtyBX53y4FPjYcWU0t0O67ZIpGeFtfK2boxC6S87cJSVFsRLXo06xo1m1e0mw26FRBizFbg07dCGUgnCoH4n4b4u/lAQMdQe8pX+jXCnK4Tx1x0HNDU4x8IGUvEFlsTBKUQKGif9TSuyoUhJnim6jbVKU26O5D8V/PcXA3VJiaUElMT5KUfzlcVIH3Wr0kjFDBiwzrSTWy85LEf9Z8a1rkoRPi7itUsvbv/P0b7fL/lOpRkLzy1ysUpguG6si/k1yKWuF9kg1EpI3awS0VXkb8Jb4dmsmoUoJWi4lmraCAVF5623v19yQVCPVLm2u1Ebelf55BYLwgXL1rE5JYWu1cehBFYMuwDHpvyOaWyb+m5QJewsYCujvqjtLLa4vQvdYV4iNcOlPDx3w5NjhsTpqX3pMlEFfucX9p5bwSvTsIR3w0Hm3fkDZMNoljHVyMSs/P3MLWOqy03eK0D1Fz3zrsms/ni7nRjo514lHs+oUFLBy6seiANqkDnrczgl4xr0IO9DDlY7k1JVJjh0xatURPyJ/3iMX6Olk6t1nsWnRR5hjBT4hj3NFVDfXhT9+MT8TdNZGw+LPqSQOwc42GmOUOwvb9Ps34C4uMkbKnQ7qRkK1hS1wSR75ByNXbcrz6SAz6O38fnYUvQe5TTSmtnjaAo8DZ1yvqiZKFr8BrqOdoF7B1WLFJeI1Fmg0ZB410e3VoFHJhkYHyAr+A4VdeV57YFl7AAAAAElFTkSuQmCC" height="20" class="me-1">
                  <label class="mb-0 me-1" for="requiringMySignature"> Requiring My Signature</label>
                  <div class="form-check form-switch form-check-custom d-inline-block ms-1 mb-0 font-size-15">
                    <div> 
                    <input id="requiringMySignature" class="form-check-input" type="checkbox" role="switch" :disabled="loading" v-model="requiringMySignature" true-value="true" false-value="false" @change="updateRequiringMySignatureQuery(requiringMySignature), handlePageChange(1)">
                  </div>
                  </div>
                </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
       
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                <thead class="text-uppercase">
                  <tr>
                    <th>Id</th>
                    <th>Company Name<br>Contact Person</th>
                    <th>Contract Amount<br>Contract Tenure</th>
                    <th>Contract Type<br>Nature Of Contract</th>
                    <th>Payment Mode<br>Maximum Exposure</th>
                    <th>Originator<br>Lead</th>
                    <th>Created Date<br>Updated Date</th>
                    <th>Status</th>
                    <th>Due Diligence Sign</th>
                    <th>Recommender Sign</th>
                    <th>Approver Sign</th>
                    <th v-if="create_modify_permission">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td :colspan="(create_modify_permission) ? 12 : 11" height="300" class="text-center text-muted">
                      <i class="uil uil-file-graph me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td :colspan="(create_modify_permission) ? 12 : 11" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  
                  
                  <tr v-for="(value, index) in returnData" :key="index" >
                    <td>
                      {{ value.application_id }}
                    </td>
                    <td> 
                      <router-link
                        class="fw-medium d-block"
                        v-b-tooltip.hover
                        :title="value.applicant.company_name "
                        :to="{
                           name: 'Edit Factoring Application',
                           params: { id: value.application_db_code },
                        }"
                        >
                      {{ value.applicant.company_name }}
                      </router-link>
                      <div>   {{ value.applicant.contact_person }}</div>
                    </td>
                    <td> 
                     {{ convertCurrencyFormat(value.contract_amount, false) }}
                     <br>
                     {{ value.contract_tenure || '-' }} Month(s)
                    </td>
                    <td> 
                    {{ value.contract_type.name}}
                    <br>{{ value.nature_of_contract.label }}
                    </td>
                    <td> 
                      {{ value.payment_mode.name}} ({{ value.payment_mode.value}})
                      <br>
                      {{ convertCurrencyFormat(value.maximum_exposure, false) }}
                    </td>
                    <td> 
                      {{ value.originator.name }} <br>
                      {{ value.lead }}
                    </td>
                    <td> 
                      {{ value.date_created }}<br>{{ value.date_updated || '-' }}
                    </td>   
                    <td>
                      <span class="badge bg-soft-success font-size-14" v-if="value.status =='approved'" v-b-tooltip.hover :title="value.comment== ' ' ? '': value.remarks">Approved</span> 
                      
                      <span class="badge bg-soft-warning font-size-14" v-if="value.status =='pending'" v-b-tooltip.hover :title="value.comment== ' ' ? '': value.comment">Pending</span> 

                      <span class="badge bg-soft-danger font-size-14" v-if="value.status =='rejected'"   
                        v-b-tooltip.hover :title="value.rejected_reason== ' ' ? '': value.rejected_reason">Rejected</span>
                    </td>
                    <td>
                      <div> 
                            <span v-if="value.justifications_and_recommendations.due_diligence_sign_off_1_status=='signed'">
                              <i class="mdi mdi-check-circle text-success"></i>
                            </span>
                            <span v-else-if="value.justifications_and_recommendations.due_diligence_sign_off_1_status=='declined'">
                              <i class="mdi mdi-close-circle text-danger"></i>
                            </span>
                            <span v-else-if="value.justifications_and_recommendations.due_diligence_sign_off_1_status==''">
                              <i class="uil uil-clock text-muted"></i>
                            </span>
                            {{ value.justifications_and_recommendations.due_diligence_sign_off_1.name  || '-'}} 
                            
                          </div>
                          <div> 
                            <span v-if="value.justifications_and_recommendations.due_diligence_sign_off_2_status=='signed'">
                              <i class="mdi mdi-check-circle text-success"></i>
                            </span>
                            <span v-else-if="value.justifications_and_recommendations.due_diligence_sign_off_2_status=='declined'">
                              <i class="mdi mdi-close-circle text-danger"></i>
                            </span>
                            <span v-else-if="value.justifications_and_recommendations.due_diligence_sign_off_2_status==''">
                              <i class="uil uil-clock text-muted"></i>
                            </span> 
                            {{ value.justifications_and_recommendations.due_diligence_sign_off_2.name  || '-'}} 
                          </div>
                    </td>
                    <td>
                      <div>
                              <span v-if="value.justifications_and_recommendations.recommender_1_status=='signed'">
                                <i class="mdi mdi-check-circle text-success"></i>
                              </span>
                              <span v-else-if="value.justifications_and_recommendations.recommender_1_status=='declined'">
                                <i class="mdi mdi-close-circle text-danger"></i>
                              </span>
                              <span  v-else-if="value.justifications_and_recommendations.recommender_1_status==''">
                                <i class="uil uil-clock text-muted"></i>
                              </span> 
                              {{ value.justifications_and_recommendations.recommender_1.name || '-' }}
                            </div>
                      <div>
                              <span v-if="value.justifications_and_recommendations.recommender_2_status=='signed'">
                                <i class="mdi mdi-check-circle text-success"></i>
                              </span>
                              <span v-else-if="value.justifications_and_recommendations.recommender_2_status=='declined'">
                                <i class="mdi mdi-close-circle text-danger"></i>
                              </span>
                              <span v-else-if="value.justifications_and_recommendations.recommender_2_status==''">
                                <i class="uil uil-clock text-muted"></i>
                              </span> 
                              {{ value.justifications_and_recommendations.recommender_2.name  || '-'}} 
                            </div>
                    </td>
                    <td>
                      <div>
                              <span v-if="value.approver1_approval_status=='approved'">
                                <i class="mdi mdi-check-circle text-success"></i>
                              </span>
                              <span v-else-if="value.approver1_approval_status=='declined'">
                                <i class="mdi mdi-close-circle text-danger"></i>
                              </span>
                              <span v-else>
                                <i class="uil uil-clock text-muted"></i>
                              </span>

                              {{ value.approver_1.name }}
                            </div>
                            <div>
                              <span v-if="value.approver2_approval_status=='approved'">
                                <i class="mdi mdi-check-circle text-success"></i>
                              </span>
                              <span v-else-if="value.approver2_approval_status=='declined'">
                                <i class="mdi mdi-close-circle text-danger"></i>
                              </span>
                              <span v-else>
                                <i class="uil uil-clock text-muted"></i>
                              </span>

                              {{ value.approver_2.name }}
                            </div>
                    </td>
                    <td class="text-center" v-if="create_modify_permission">
                      <router-link
                      class="btn btn-outline-info px-2 py-1 font-size-15 rounded me-2"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                           name: 'Edit Factoring Application',
                           params: { id: value.application_db_code },
                        }"
                        ><i class="uil uil-edit"></i> </router-link
                      >
                      <button @click="removeApplication(value)" type="button" v-b-tooltip.hover
                        title="Delete" class="btn btn-outline-danger px-2 py-1 font-size-15 rounded"><i class="uil uil-trash-alt"></i> </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
/**
 * Page
 */
const PageTitle = "Factoring Application"
const usersDefault = {
  "account_db_code": "",
  "username": "",
  "name": "All Originator",
  "full_name": "",
  "nric_no": "",
  "signature": "",
  "position": "",
  "email": "",
  "status":""
}
export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker,
    Multiselect
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      seletedMethod: "",
      sortBy:"",
      sortByNatureOfContract:"",
      requiringMySignature:false,
      paymentMode:"",
      status: "",
      searchKeyword: "",
      filterType:"created_date",
      selectedUser: usersDefault,
      userList: [usersDefault],
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed:0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      create_modify_permission:false,
      nature_of_contract: [
        {
          label: "Recurring",
          value: "recurring",
        },
        {
          label: "One Off",
          value: "one_off",
        },
        {
          label: "Milestone/Staggered",
          value: "milestone_staggered",
        },
        {
          label: "Progress",
          value: "progress",
        },
      ],
    };
  },
  middleware: "authentication",
  async mounted(){
    this.reload();
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    this.getUsers();

    if ('requiringMySignature' in this.$route.query) {
      if (this.$route.query.requiringMySignature){
        this.requiringMySignature=true
      }
    }else{
      this.requiringMySignature=false
      this.updateRequiringMySignatureQuery('false')
    }
    
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  methods:{
    updateRequiringMySignatureQuery(value) {
        // Update the query string
        this.$router.replace({ query: { 'requiringMySignature': value } });
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.searchKeyword);
        //this.exportData(1)
      },
      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.$router.replace({'query': null});
        this.requiringMySignature = false; 
        this.sortBy= ""
        this.searchKeyword= ""
        this.paymentMode=""
        this.status= ""
        this.filterType="created_date"
        this.date_range = this.$refs.commonFunc.last30Days()
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    getUsers() {
      console.log("getUsers");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999");
      axios({
        method: "post",
        url:  appConfig.APIHost + "controller/user/getAllUserList",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            resData.data.forEach((element) => {
              this.userList.push({
                  account_db_code: element.account_db_code,
                  username: element.username,
                  name: element.name,
                  full_name: element.full_name,
                  nric_no: element.nric_no,
                  signature: element.signature,
                  position: element.position,
                  email: element.email,
                  status: element.status
              });
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    removeApplication(value) {
      Swal.fire({
        icon: 'info',
        title: `Confirm Deletion of Factoring Application`,
        html: `<p>Are you certain you wish to delete this factoring application from ${value.applicant.company_name}? </p><p class="mb-0">This action is irreversible, so please ensure that you want to proceed.</p>`,
        confirmButtonColor: '#FA6541',
        iconColor: '#202360',
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.loading = true
          this.disabled = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("applicationDbCode", value.application_db_code);
          axios({
            method: "post",
            url: appConfig.APIHost + "controller/application/deleteApplication",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.handlePageChange(1)
              Swal.fire({
                icon: 'success',
                title: `${value.applicant.company_name} Deleted Successfully`,
                html: 'The selected factoring application has been successfully deleted.',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              })
            } else if (resData.status == 440) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            }
            this.loading = false
            this.disabled = false
            this.$Progress.finish();
          }).catch((error) => {
            this.disabled = false;
            this.loading = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      })
      
      
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("paymentMode", this.paymentMode);
        bodyFormData.append("status", this.status);
        bodyFormData.append("sortBy", this.sortBy);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
        bodyFormData.append("originator", this.selectedUser.username);
        bodyFormData.append("natureOfContract", this.sortByNatureOfContract)
        bodyFormData.append("requiringMySignature", this.requiringMySignature)
        console.log(`page: ${pages}, limit: ${this.perPage}, keyword: ${keyword}, paymentMode: ${this.paymentMode}, status: ${this.status}, sortBy: ${this.sortBy}, dateStart: ${this.date_range[0]}, dateEnd: ${this.date_range[1]}, originator: ${this.selectedUser.username}, natureOfContract: ${this.sortByNatureOfContract}`);

        axios({
            // method: "get",
            // url: `/api/factoring_applicationV2.json`,
            method: "post",
            url:  appConfig.APIHost + "controller/application/getAllApplicationList",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            this.totalData = resData.total
            var responseData = resData.data
            
            if (resData.status == 200) {
              this.returnData = responseData
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
                var transaction_id = ""
                if (randomMode=="UPI"){
                  transaction_id = "320020673657"
                }else{
                  transaction_id = "IMPS/123443211240"
                }

                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode":randomMode.toString(),
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "transaction_id":transaction_id,
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 440){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     localStorage.clear();
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='factoring_application')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "factoring_application" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>